<template>
  <div 
    class="m-field"
    :class="{ disabled, success, outlined, warning, light, dark, center }"
  >
    <slot name="label">
      <span
        v-if="label!==undefined"
        class="m-field-label text-caption"
        :class="{ 'mx-2 px-1': outlined }"
      >
        {{ label }}
      </span>
    </slot>
    <v-card
      flat
      :dark="dark"
      :light="light"
      class="m-field-container"
    >
      <div class="m-field-content pt-3 pb-2">
        <slot default />
      </div>
      <slot name="loading">
        <v-progress-linear
          v-show="loading"
          indeterminate
          absolute
          bottom
          :color="success ? 'success' : color"
          class="m-field-loading"
        />
      </slot>
    </v-card>
    <div 
      v-if="warning||hint||!hideDetails"
      class="m-field-messages text-caption mt-2 pb-2"
      :class="{ 'px-3': outlined }"
    >
      <slot name="messages">
        <div
          :class="{ 'warning--text': warning }"
          class="m-field-message"
        >
          {{ warning ? warning : hint||null }}
        </div>
      </slot>
    </div>
  </div>
</template>

<style lang="scss">

#app .m-field {
  position: relative;
  text-align: left;
  background-color: transparent !important;
}
.m-field.light.outlined .m-field-container {
  border: 1px solid rgba(0, 0, 0, 0.38) !important;
}
.m-field .m-field-container {
  background-color: transparent !important;
  color: inherit !important;
  padding: 1px;
}
.m-field.dark.outlined .m-field-container {
  border: 1px solid rgba(255, 255, 255, 0.24) !important;
}
.m-field.success {
  color: var(--v-success-base) !important;
}
.m-field.outlined.success .m-field-container, .m-field.outlined.warning .m-field-container {
  border: 2px solid !important;
  padding: 0 !important;
}
.m-field.warning {
  color: var(--v-warning-base) !important;
}

.m-field.disabled {
  opacity: .8;
  pointer-events: none;
}
.m-field .m-field-label {
  position: absolute;
  top: 0;
  z-index: 1;
  margin-top: -9px;
  color: rgba(0, 0, 0, 0.6);
  background-color: var(--v-background-base);
  letter-spacing: normal !important;
}
.m-field.center .m-field-label {
  left: 50%;
  transform: translateX(-50%);
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.m-field.dark .m-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.m-field.disabled .m-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.m-field.success .m-field-label, .m-field.warning .m-field-label {
  color: inherit;
}

.m-field.center .m-field-messages {
  text-align: center;
}
.m-field .m-field-messages {
  letter-spacing: normal !important;
}
.m-field .m-field-messages .m-field-message {
  min-height: 14px;
  line-height: 14px;
}

.m-field .m-field-loading {
  margin-bottom: 0;
}

</style>

<script>
  import {
    mdiCheckboxBlankOutline, 
    mdiCheckboxMarked,
  } from '@mdi/js'

  export default {
    props: {
      value: {
        type: [String, Array, Object, Number],
        default: undefined
      },
      label: {
        type: String,
        default: undefined
      },
      color: {
        type: String,
        default: 'primary'
      },
      hint: {
        type: String,
        default: undefined
      },
      rules: {
        type: Array,
        default: () => []
      },
      dark: {
        type: Boolean,
        default: undefined
      },
      light: {
        type: Boolean,
        default: undefined
      },
      outlined: {
        type: Boolean,
        default: true
      },
      center: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      loading: {
        type: Boolean,
        default: false
      },
      hideDetails: {
        type: [Boolean, String],
        default: false
      },
      validate: {
        type: [Boolean, Function],
        default: true
      },
      success: {
        type: Boolean,
        default: undefined
      },
    },
    
    data: () => ({
      icons: {
        checked: mdiCheckboxMarked,
        unchecked: mdiCheckboxBlankOutline
      },
      controller: {
        value: null
      }
    }),

    computed: {
      warning () {
        const validate = _.isBoolean(this.validate) ? this.validate : this.validate(this.value);
        console.log(validate);
        const value = this.value;
        const warning = this.rules.length>0 && validate ? _.find(this.rules, (valid) => {
          return valid(value)!==true;
        }) : null;
        return _.isNil(warning) ? false : warning(value);
      }
    },

    watch: {
      
    },

    methods: {
      

    },
  }
</script>